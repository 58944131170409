.ScoreContainer{
    margin-bottom: 2rem;

}

.ScoreContainer .Score{
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;

}

.ScoreContainer .Score svg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    /* background-color: red; */
    transform: rotate(270deg);


}
.ScoreContainer .Score svg circle{
    width: 100%;
    height:100%;
    fill:transparent;
    stroke-width: 5;
    stroke : #191919;
    transform: translate(5px, 5px);
}

.ScoreContainer .Score svg circle:nth-child(2){
    stroke: var(--clr);
    stroke-dasharray: 500;
    stroke-dashoffset:calc(500 - (500 * var(--num))/100);
    opacity: 0;
    animation: fadeIn 1s linear forwards;
    animation-delay: 2.5s;
}

@keyframes fadeIn 
{
 
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

.dot{
    position: absolute;
    inset: 1px;
    z-index: 10;
    transform: rotate(calc(3.6deg * var(--num)));
    animation: animateDot 2s linear forwards;
}

@keyframes animateDot {

    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(calc(3.6deg * var(--num)));;
    }
    
}

.dot::before{
    content:"";
    position: absolute;
    top:-5px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 0 10px var(--clr),
    0 0 30px var(--clr);
    background-color: var(--clr);

}

.number{
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 1s linear forwards;
    animation-delay: 2.5s;
}

.number h2{
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 900;
    font-size:78px;
    line-height: 153.6%;
    text-align: center;
    /* letter-spacing: 0.3em; */
    text-transform: uppercase;
    /* background: linear-gradient(180deg, #7CE8FF 27.6%, rgba(103, 214, 239, 0.95) 67.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; */
    /* text-shadow: 0 0 10px var(--clr); */
}

.number h2 span{
    font-weight: 300;
    /* color: #fff; */
    font-size: 0.5em;
}
